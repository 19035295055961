<template>
  <div>
    <BaseImg title="Contact" bgName="bg_contact.png"> </BaseImg>
    <div class="bottom">
      <div class="left">
        <div class="goldTitle">contact</div>
        <div class="bigTitle">get in touch</div>
        <div class="content">
          If you have any questions about our products and services please
          contact us through our contact form.
        </div>
        <div class="content margin13">
          Alternatively you can contact us directly via email or phone.
        </div>
        <!-- <div class="goldTitle margin50">phone</div>
        <div class="content margin13">Phone: 0800 377 366</div>
        <div class="content">From overseas: +64 9 2158966</div>
        <div class="content">Fax: +64 9 3023246</div>
        <div class="content">Monday - Friday</div>
        <div class="content">10AM - 6PM</div> -->
        <div class="goldTitle margin50">email</div>
        <div class="content margin13">General Enquiry – info@auskyco.com</div>
        <div class="content">Transactions – enquiry@auskyco.com</div>
      </div>

      <ContactForm class="right"> </ContactForm>
    </div>
  </div>
</template>

<script>
import BaseImg from '@/components/BaseImg.vue'
import ContactForm from '@/components/ContactForm.vue'

export default {
  components: {
    BaseImg,
    ContactForm
  }
}
</script>

<style scoped>
.bottom {
  width: 1440px;
  padding: 78px 100px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.left {
  width: 600px;
  padding-top: 22px;
  box-sizing: border-box;
}
.goldTitle {
  font-size: 24px;
  font-weight: 600;
  color: #bc9a41;
  line-height: 33px;
  text-transform: uppercase;
}
.bigTitle {
  font-size: 48px;
  font-weight: 500;
  color: #163257;
  text-transform: uppercase;
}
.margin50 {
  margin-top: 50px;
}
.margin13 {
  margin-top: 13px;
}
.content {
  font-size: 18px;
  font-weight: 400;
  color: #163257;
  line-height: 24px;
}
.right {
  width: 600px;
}
@media only screen and (max-width: 600px) {
.bottom {
  width: 100%;
  padding: 5vw;
  display: block;
  justify-content: space-between;
  margin: 0 auto;
}
.left {
  width: 100%;
  padding-top: 2vw;
}
.goldTitle {
  font-size: 1.5rem;
  font-weight: 500;
  color: #bc9a41;
  line-height: 1.5rem;
  text-transform: uppercase;
}
.bigTitle {
  font-size: 1.5rem;
  font-weight: 500;
  color: #163257;
  line-height: 3.5rem;
  text-transform: uppercase;
}
.margin50 {
  margin-top: 3vw;
}
.margin13 {
  margin-top: 2vw;
}
.content {
  font-size: 1rem;
  font-weight: 400;
  color: #163257;
  line-height: 1.5rem;
}
.right {
  width: 100%;
  margin-top: 3vw;
}
}
</style>
